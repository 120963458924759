<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Banner Form
    </template>
    <template slot="body">
      <div class="photo-grid photo-grid-1-col">
        <div
          v-if="form.image_url"
          class="img-container"
          :style="`background-image: url('${form.image_url}')`"
        >
          <div
            class="buttons-overlay"
          >
            <md-button
              class="md-just-icon md-sm md-danger md-round"
              @click="removeImage"
            >
              <md-icon class="m-0">
                delete
              </md-icon>
            </md-button>
          </div>
        </div>
        <FormUploader
          v-else
          class="image-uploader"
          :max-size="1024*20"
          @input="imageSelected($event, false)"
          @error="fireError"
        >
          <div class="image-button">
            <md-icon>photo_camera</md-icon>
            <span>Click to upload Image</span>
          </div>
        </FormUploader>
      </div>

      <FormText
        v-model="form.title"
        label="Title"
        icon="description"
      />
      <FormDate
        v-model="form.expires_at"
        label="Expiration Date"
      />
      <FormSelect
        v-model="linkTo"
        :options="linkOptions"
        label="Link To"
        icon="link"
      />

      <ActivitySelect
        v-if="linkTo === 'activity'"
        v-model="form.activity_id"
      />
      <NewsSelect
        v-if="linkTo === 'news'"
        v-model="form.news_id"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-primary"
        @click="onSave"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText, FormDate, FormUploader, FormSelect,
} from '@/components/Inputs';

import {
  ActivitySelect, NewsSelect,
} from '@/components/Inputs/selects';

export default {
  components: {
    FormText,
    FormDate,
    FormUploader,
    FormSelect,
    ActivitySelect,
    NewsSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    linkTo: 'activity',
    linkOptions: [
      { id: 'activity', name: 'Activity' },
      { id: 'news', name: 'News' },
    ],
    form: {
      image_url: null,
      image_file: null,
      title: null,
      expires_at: null,
      activity_id: null,
      news_id: null,
    },
  }),
  watch: {
    linkTo() {
      this.form.activity_id = null;
      this.form.news_id = null;
    },
  },
  mounted() {
    if (this.item) {
      this.form = { ...this.item };
    }

    if (this.form.news_id) {
      this.linkTo = 'news';

      setTimeout(() => {
        this.form.news_id = this.item.news_id;
      }, 100);
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    imageSelected(imageFile) {
      const imageURL = URL.createObjectURL(imageFile);

      this.form.image_file = imageFile;
      this.form.image_url = imageURL;
    },
    removeImage() {
      this.form.image_file = null;
      this.form.image_url = null;
    },
    onSave() {
      this.loading = true;

      const f = this.paramsSanitization(this.form);
      const fd = new FormData();
      fd.append('file', this.form.image_file);
      fd.append('title', f.title);
      fd.append('expires_at', f.expires_at);
      fd.append('activity_id', f.activity_id);
      fd.append('news_id', f.news_id);

      if (this.form.banner_id) {
        this.request(`app/banners/${this.item.banner_id}`, 'put', fd, () => {
          this.$emit('saved');
          this.close();
        }, () => {
          this.loading = false;
        });
      } else {
        this.request('app/banners', 'post', fd, () => {
          this.$emit('saved');
          this.close();
        }, () => {
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style></style>
